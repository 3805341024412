
// Fonts
//@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700&subset=latin-ext');
// Variables
@import "variables2";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

html{
	min-height: 100%;
	position: relative;
}
body{
	padding-top:100px;
	margin-bottom: 320px;
	&.has-sidebar{
		padding-left: 48px;
		transition: all 0.1s linear;
		overflow-x: hidden;
	}
	&.has-sidebar-active{
		padding-left: 208px;
		transition: all 0.1s linear;
		overflow-x: hidden;
	}
	@media (min-width: $screen-xlg) {
    padding-left:0!important;
  }

}

.navbar-brand {
  line-height: 36px;
	font-weight: bold;

	span{
		font-weight: 100;
	}

  > img {
    display: block;
		float: left;
		margin-right: 10px;
  }

}
.container,
.container-fluid {
  > .navbar-header,
  > .navbar-collapse {
    @media (min-width: $grid-float-breakpoint) {
			text-align: center;
			line-height: 0;
    }
  }
}
.btn:active, .btn.active{
	box-shadow: none;
}

.confirm-btn-group{
	.btn{
		border-radius: 100%;
		height: 32px;
		width: 32px;
	}
}
.navbar-nav {
	&.navbar-right{
		> li > a {
			line-height: 48px;
			i{
				line-height: 48px;
				color:$gray;
			}
			span{
				background-color: $brand-danger;
				position: absolute;
		    width: 16px;
		    height: 16px;
		    overflow: hidden;
		    border-radius: 100%;
		    padding: 0;
		    color: $gray-light;
		    line-height: 16px;
		    font-size: 12px;
		    text-align: center;
		    right: 10px;
		    top: 20px;
			}
			&:hover, &:active{
				i{
					color:$brand-info;
				}
			}
		}
		> li {
			&.dropdown ul{
				box-shadow: -4px 10px 19px -8px rgba(0,0,0,0.22);
			}
			&.profil{
				img{
					height: 36px;
					margin:6px
				}
				ul{
					width: 350px;
					padding: 0px;
					background-color: $gray-lighter;
					li{
						margin-bottom: 1px;
						border: none;
						&.head{
							background: $gray-light;
							padding-bottom: 10px;
							>img{
								display: inline-block;
								vertical-align: bottom;
								height: 50px;
								margin: 0px;
							}
							a{
								display: inline-block;
								background: none!important;
								text-align: inherit;
								line-height: initial;
								&:hover{
									color:$brand-info;
								}
								p{
									margin: 0;
									margin-top: 6px;
									&.small{
										margin: 0 0 10px;
										color:$gray;
									}
								}
							}
						}
						&.link{
							text-align: center;
							background: $gray-light;
							a{
								background: none!important;
								color:$gray;
								padding: 15px;
								text-align: inherit;
								line-height: initial;
								&:hover{
									color:$brand-info;
								}
							}
						}
						&.link_second{
							padding: 0;
							margin-bottom: 0px;
							a{
								background: none;
								height: 100%;
								width: 100%;
								padding: 12px;
								color:$gray;
								line-height: initial;
								border-radius: 0px;
								&:hover{
									background:$brand-info;
									color:$gray-light;
								}
							}
						}
					}
				}
			}
			&.alerts ul{
				width:400px;
				max-height:500px;
				overflow:auto;
				padding:0px;
				background-color: $gray-lighter;

				li a{
				 	font-weight:bold;
				}
				li{
					 background-color: $gray-light;
					 margin-bottom: 1px;
					 height:auto;
					 display: flex;
					 align-items: center;
				   min-height:69px;
					 position:relative;
					 border: none;
					 padding: 10px 8px;
					 .confirm-btn-group{
						 margin-right: 15px;
						 width: 74px;
						 .btn{
							 color:$gray;
							 &:hover{
								 background: $brand-info;
								 color:$gray-light;
							 }
						 }
					 }
				}
				h4{
					font-weight:normal;
					margin: 0px;
					font-size: 14px;
					max-width: 280px;
				}
				button.close{
					position:absolute;
					top:20px;
					right:8px;
					font-weight:normal;
					font-size: 25px;
				}

				&::-webkit-scrollbar-track
				{
					border-radius: 0px;
					background-color: rgb(27,28,28);
				}

				&::-webkit-scrollbar
				{
					width: 7px;
					background-color: #F5F5F5;
				}

				&::-webkit-scrollbar-thumb
				{
					border-radius: 0px;
					background-color: rgba(255,255,255,0.8);
				}
			}
			&.alerts.messages li{
							padding:16px;
							a{
								padding: 0;
								margin: 0;
								line-height: normal;
								width: 100%;
								line-height: 16px;
							}
							a:hover{
								color:inherit;
							}
						  img{
								width: 32px;
								float: left;
								padding: 0;
							}
							.head-messages-navbar{
								font-size: 15px;
								line-height: 19px;
								text-align: left;
								padding-left: 40px;
							}
							.date-messages-navbar{
								font-weight: 200;
								float: right;
								text-align: right;
								color: $gray;
								font-size: 11px;
								margin: 0;
							}
							.text-messages-navbar{
								font-weight: 200;
								margin: 0px;
								color: $gray;
								line-height: 13px;
								font-size:11px;
								text-align: left;
								padding-left: 44px;
							}
							&.only_link{
								border:none;
								line-height: 32px;
								background: $gray-lighter;
								min-height: inherit;
								margin: 0;
								padding: 8px;
								&:hover{
									background:$brand-info;
									a{
										color:$gray-light;
									}
								}
								a{
									color: $gray;
									text-align: center;
									font-weight: 200;
								}
							}

			}
		}
	}
  > li > a {
    line-height: 28px;
		text-align: center;

		i {
			display: block;
			margin: 0 auto;
			font-size: 20px;
			color: $brand-info;
		}
  }
	@media (min-width: $grid-float-breakpoint) {
		&.navbar-center{
			>li{
				display: inline-block;
			}
		}
		margin: auto;
		float:none;
		display: inline-block;
	 > li {
		 float: left;
		 > a {
			 padding-top:    13px;
			 padding-bottom: 13px;
		 }
	 }
 }
}
.btn{
	&.btn-default{
		&:hover,&:focus:hover{
			background: $brand-info;
			color: $gray-light;
			outline: none;
			border-color: $brand-info;
		}
		&:active{
			background-color: $brand-active;
			border-color: $brand-active;
			color: $gray-light;
			outline: none;
		}
		&.active{
			 background: $gray-light;
			 border-color: $brand-info;
			 color: $brand-info;
			 outline: none;
	  }
		&:focus{
			background: $gray-light;
			border-color: $gray;
			color: $gray;
			outline: none;
		}
	}
	&.btn-info{
		&:hover, &:focus, &.active{
			border-color: $brand-info;
			color: $gray-light;
			background: $brand-info;
			outline: none;
		}
	}
	&.btn-danger{
		&:hover, &:focus, &.active{
			border-color: $brand-danger;
			background: $brand-danger;
		}
	}
	&.btn-success{
		border-color: $brand-success;
		background: $gray-light;
		color: $brand-success;
		&:hover, &:focus, &.active, &:active, &:focus:hover{
			border-color: $brand-success;
			color: $gray-light;
			background: $brand-success;
			outline: none;
		}
	}
}
.table-responsive{
	margin-top: 10px;
	border: 1px solid $gray-base;
	border-radius: $border-radius-large;
}
.table{
	margin-bottom: 0;
	&.table-hover tr{
		cursor: pointer;
	}
	th{
		background: $gray-base;
		font-weight: normal;
		&.bold{
			font-weight: 800;
		}
	}
}
.form-control{
	&:hover, &:focus, &.active{
		outline: none;
		@include box-shadow(none);
	}
}
.info_event{
	p{
		margin-top: 10px;
		line-height: 40px;
		font-size: 17px;
		span{
			background: $gray;
			color: $gray-light;
			border-radius: 100%;
			width: 40px;
			height: 40px;
			display: inline-block;
			text-align: center;
			line-height: 37px;
			margin-right: 10px;
			font-size: 16px;
			&.brand-info{
				background: none;
				color:$brand-info;
				border:3px solid $brand-info;
			}
			&.brand-danger{
				background: $brand-danger;
			}
			&.brand-success{
				background: $brand-success;
			}
		}
		&.small{
			line-height: 30px;
			font-size: 15px;
			margin-top: 5px;
			span{
				height: 30px;
				width: 30px;
				font-size: 12px;
				line-height: 32px;
				margin-left: 5px;
				margin-right: 15px;
			}
		}
	}
}
.profil-edit{
	.btn{
		margin-top: -20px;
	}
}
.panel{
	&.panel-padding{
		padding: 25px;
	}
	.panel-body{
		padding: 25px;
	}
	.panel-heading{
		color:$gray;
		text-transform: uppercase;
	}
	&.panel-primary{
		background: $gray-base;
	}
}
.label{
	font-weight: 300;
	font-size: 15px;
	line-height: 20px;
	padding: 8px 15px;
	margin-bottom: 5px;
	display: inline-block;
	margin-right: 5px;
	min-height: 36px;
	&.label-default{
		color:$gray-dark;
		border-radius: $border-radius-large;
		width: 100%;
		text-align: left;
	}
	&.label-info{
		border-radius: 50px;
	}
}

.lighter{
	color:$gray;
}
.margin-right{
	margin-right: 5px;
}
.show_hide{
	transition: all 0.5s ease;
}
.show_hide.show{
	visibility: visible;
	opacity: 1;
	display: block!important;
}
.show_hide.hide{
	visibility:hidden;
	opacity:0;
	display: block!important;
}


.radio-inline + .radio-inline, .checkbox-inline + .checkbox-inline{
	margin-left: 0px;
}
.radio-inline, .checkbox-inline{
	margin-right: 10px;
}
#drop_app{
  position: fixed;
  background: rgba(250,250,250,0.7);
  top:0px;
  left:0px;
  width: 100%;
  height: 100%;
  display: none;
  text-align: center;
  color:rgb(10,10,10);
  z-index: 10000;
  font-size: 30px;
  text-transform: lowercase;
  letter-spacing: 1px;
}
#drop_app.in{
  display: block;
}
#drop_app .box{
  margin-top: 20%;
}
#drop_app i{
  font-size: 100px;
  display: block;
  margin-bottom: 20px;
}
.margin_top_20{
	margin-top:0px;
}
.margin_top_5{
	margin-top:5px;
}
.progress{
	height: 10px;
}
.messages_recivers_box{
	width: 100%;
	border: 1px solid #ccc;
	border-radius: $border-radius-large;
}
.messages_recivers_box:focus{
	border: 1px solid #fff;
}
.messages_recivers_box input{
	border: none;
	box-shadow: none;
	width:300px!important;
	border-radius: $border-radius-large;
}
.messages_recivers_box span{
    float: left;
    height: 20px;
    line-height: 16px;
    margin: 2px;
    margin-top: 4px;
		min-height: 30px;
		color:$gray-dark;
		cursor: pointer;
}
.messages_recivers_box span i{
	font-style: normal!important;
}
.remove-margin-right{
	//padding-right: 5px;
}
.search_top{
    width: auto;
    margin-top: 0px;
    height: 30px;
    margin-right: 10px;
    z-index: 19;
    position: relative;
}
.search_top_2{
    width: auto;
    height: 30px;
    margin-right: 10px;
    z-index: 19;
    position: relative;
}
select.search_top, select.search_top_2{
	padding-top: 0px;
	padding-bottom: 0px;
}
.bootstrap-datetimepicker-widget.dropdown-menu{
	z-index: 10000;
	background: #fff;
}
.event_title{
	margin-bottom: 10px;
	.name{
		display: inline-block;
		margin-right: 10px;
		font-size: 27px;
		font-weight: bold;
	}
	.status{
	  display: inline-block;
		border-radius: 50px;
		background: $gray-base;
		color: $gray;
		padding: 5px 15px;
  	text-transform: lowercase;
		vertical-align: text-bottom;
		&.confirm{
			background: $brand-info;
			color: $gray-light;
			vertical-align: text-bottom;
		}
	}
}
.table tbody>tr>td{
    vertical-align: middle;
}
			/*add events */
			.time select:disabled{
				opacity:0.3;
			}
			.no-pad{
				padding:1px;
			}
			/*equipment*/
			.equipment .nav ul{
				list-style:none;
				padding:0px;
			}
			.equipment .nav ul li{
				display:inline-block;
			}
			.equipment.single .nav{
				float:right;
			}

			/*file input */
			.btn-file {
			    position: relative;
			    overflow: hidden;
			}
			.btn-file input[type=file] {
			    position: absolute;
			    top: 0;
			    right: 0;
			    min-width: 100%;
			    min-height: 100%;
			    font-size: 100px;
			    text-align: right;
			    filter: alpha(opacity=0);
			    opacity: 0;
			    outline: none;
			    background: white;
			    cursor: inherit;
			    display: block;

			}
			.ui-autocomplete{
				z-index:2000!important;
			}
			.img_employer img{
				float:left;
				height:120px;
				width:120px;
				margin-right:20px;
			}
			/*nowe polepszone*/
			/*
			:focus{
				box-shadow:0 0 0 0 rgba(0,0,0,0)!important;
				border-color:rgb(27,28,28)!important;
				outline:none!important;
				outline-offset: 0px!important;

			}*/
			/*
			a:focus, .row a:focus{
				border:none!important;
				color:rgb(255,255,255)!important;
			}
			*/
			/*
			.dropdown-menu a{
				color:rgb(255,255,255)!important;
			}
			.dropdown-menu a:hover{
				color:rgb(27,28,28)!important;
			}
			*/
			.events_list a:focus{
				border:none!important;
				color:rgb(27,28,28)!important;
			}
			.back:focus{
				color:rgb(27,28,28)!important;
			}

/*
			.profil .info a:hover{
				color:#fff!important;
			}
			*/

			.counted{
		    	    			display:block!important;
		    	    		}
			.icon.border{
				border:1px solid #fff;
				border-radius: 100%;
			}

			/*event single*/
			.event.single .title h2{
				float:left;
				margin-right:25px;
				margin-top:5px;
				margin-bottom:5px;
				padding:0px;
			}
			.event.single .title{
				float:none;
			}
			.event.single .title .btn{
				border:2px solid rgb(27,28,28);
				color:rgb(27,28,28);
				width:230px;
				background:none;
			}
			.event.single .title .btn.p1{
				border-color:green;
				color:green;
			}
			.event.single h4{
				margin-bottom:3px;
				margin-top:25px;
			}
			.event.single p{
				margin-top:3px;
				margin-bottom:3px;
			}
			.event.single .nav.top div{
				position:relative;
				padding-left:0px;
				padding-right:5px;

			}
			.event.single .nav.top .btn{
				width:100%!important;
				border:none !important
			}
			.event.single table tr td{
				height:34px;
				line-height:34px;
			}
			.event.single table tr td .btn{
				height:34px;
				margin:0px;
				line-height: 34px;
				padding:0px;

			}
			.event.single #sprzet tr td input{
				height:34px;
				line-height: 28px;
				padding:2px;
				margin-top:2px;
			}
			.event.single #sprzet tr td input.form-control{
				width:50px;
				float:left;
				margin-right:10px;
			}
			.event.single #sprzet tr td input[type="checkbox"]:before{
				height:30px;
				float:left;
			}
			.event.single #sprzet tr td input[type="checkbox"]{
				float:left;
			}
			.event.single #sprzet tr td span{
				line-height:38px;
				padding:10px;
			}

			.equipment #items table input{
				height:34px;
				line-height: 28px;
				padding:2px;
				margin-top:2px;
			}
			.equipment #items table input.form-control{
				width:50px;
				float:left;
				margin-right:10px;
			}
			.equipment #items table input[type="checkbox"]:before{
				height:30px;
			}
			/*home*/
			.home .row{
				margin-top:10px;
				margin-bottom:8px;
			}
/*calendar nav*/
.nav_calendar{
		font-size:1.5em;
		margin:12px auto;
		text-align: center;
		clear:both;
		margin-top: 0px;
		a{
				margin:5px;
				display: inline-block;
				width:40px;
				height:30px;
				cursor: pointer;

				&.today{
						width: auto;
				}
				&.today span{
						font-size: 0.6em;
						position: relative;
						top:-2px;
						padding: 0 8px;
						width: 100%;

				}
				&:hover{
						text-decoration: none;
				}
		}
		span{
				width:170px;
				display:inline-block;
				text-transform: uppercase;
				font-weight: bold;
		}
}
			/*calender all*/
.events.calendar{
	  	ul{
				position:relative;
				list-style:none;
				width:100%;
				padding:0;
				margin:0;
				.day{
					float:left;
					width:14.27%;
					display:block;
					position:relative;
					height:215px;

				 &>div{
						background:$gray-light;
						margin:2.5px;
						padding:4px;
						height:210px;
						position:relative;
						border-radius: $border-radius-large;
					}
					&.week{
						height:32px;
						background:$gray-light;
						font-size:13px;
						line-height:32px;
						margin:0px;
						margin-bottom:5px;
						display: block;
						text-align: center;
						text-transform: uppercase;
						font-weight: 800;
					}
					&:hover{
						.create_event_btn{
							opacity: 1;
							visibility: visible;
						}
						.create_event{
							opacity: 1;
							visibility: visible;
						}
					}
					.create_event_btn{
						position: absolute;
						opacity: 0;
						visibility: hidden;
						transition: all 0.05s ease;
						font-size: 24px;
						top:10px;
						right: 10px;
						color:$gray;
						&:hover{
							color:$brand-info;
						}
					}
					.create_event{
						position: absolute;
						opacity: 0;
						visibility: hidden;
						top:45px;
						right: 0;
						height:160px;
						padding-top: 20px;
						background: $gray-light;
						text-align: center;
						li{
							border-top: 1px solid $gray;
							line-height: 40px;
							font-weight: bold;
							&:last-child(){
								border-bottom: 1px solid $gray;
							}
						}
					}

				}
				.day .special{
					background:rgba(200,200,200,0.1);
				}
				#today{
					outline: solid 1px $brand-info;
					border-radius: $border-radius-large;
				}
				.day p.hiddena{
					visibility: hidden;
				}
				.day .number{
					margin-bottom:3px;
					color:$gray;
					font-size: 32px;
					font-weight:800;
				}
				.day .week_day{
					display:none;
				}
				.day .event{
					display:block;
					opacity:0.8;
				}
				.day .event:hover{
					opacity:1;
				}
				.day .events{
					height:110px;
					overflow:auto;
				}
				.day .events::-webkit-scrollbar-track
				{
					border-radius: 0px;
					background-color: rgb(27,28,28);
				}

			  .day .events::-webkit-scrollbar
				{
					width: 7px;
					background-color: #F5F5F5;
				}

				.day .events::-webkit-scrollbar-thumb
				{
					border-radius: 0px;
					background-color: rgba(255,255,255,0.8);
				}
				.day .event.add{
					display:none;
					position:absolute;
					bottom:5px;
				}
				.day:hover .event.add{
					display:block;
				}
		}
	}
.dropdown-menu{
	padding: 0;
	> li {
			border-bottom: 2px solid $gray-lighter;
			&:last-child(){
				border-bottom: none;
			}
	}
	> li > a {
    line-height: 38px;
		text-align: center;
		cursor: pointer;
		&:hover{
			background: none;
			color:$brand-info;
		}
	}
}
.btn-group.open .dropdown-toggle, .btn-group .dropdown-toggle:focus, .btn-group .dropdown-toggle:hover {
  @include box-shadow(none);
	background: $gray-light!important;
	border-color: $brand-info!important;
	color:$brand-info!important;

}
footer{
	background: $gray-light;
	margin-top:30px;
	padding-top:64px;
	padding-bottom:20px;
	min-height: 300px;
	position: absolute;
	bottom: 0px;
	left:0px;
	width: 100%;
	&.has-sidebar{
		padding-left: 48px;
		transition: all 0.1s linear;
		overflow-x: hidden;
	}
	&.has-sidebar-active{
		padding-left: 208px;
		transition: all 0.1s linear;
		overflow-x: hidden;
	}
	@media (min-width: $screen-xlg) {
    padding-left:0!important;
  }
	a{
		display: block;
	}
	.helpme{
		position: absolute;
		bottom: 0;
		left:0;
		p{
			line-height: 18px;
			font-size: 13px;
			margin: 5px 10px;
			&.bold{
				font-weight: bold;
			}
		}
	}
	.author{
		position: absolute;
		bottom:0;
		padding-right: 15px;
		right: 0;
		p{
			margin: 0;
		}
	}

}



@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0 rgb(100,100,100);
	  height: 4em;
	 }
  40% {
	  box-shadow: 0 -2em rgb(100,100,100);
	  height: 5em;
	}
}
@keyframes load1 {
	0%,
	80%,
  100% {
	  box-shadow: 0 0 rgb(100,100,100);
		height: 4em;
  }
	40% {
		box-shadow: 0 -2em rgb(100,100,100);
    height: 5em;
	}
}
.btn.btn-back{
	padding-left: 0px;
	font-size: 20px;
	float: left;
  padding: 0;
  margin-right: 10px;
 line-height: 32px;
}

img.icon{
	height:100%;
  position:relative;
  margin-left:5px;
  padding:3px;
}
h2{
	padding-bottom: 25px;
	float: none;
	margin-top: 0px;
	line-height: 32px;
	font-size: 24px;
	font-weight: bold;
}

.navbar-brand{
  font-weight: bold;
}
.navbar-brand span{
  font-weight: 100;
}
.loader,
.loader:before,
.loader:after {
	background: #ffffff;
	-webkit-animation: load1 1s infinite ease-in-out;
	animation: load1 1s infinite ease-in-out;
	width: 1em;
	height: 4em;
}
.loader:before,
.loader:after {
	position: absolute;
	top: 0;
	content: '';
}
.loader:before {
	left: -1.5em;
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}
.loader {
	text-indent: -9999em;
	margin: 88px auto;
	position: relative;
	font-size: 11px;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}
.loader:after {
	left: 1.5em;
}
.simple-loader{
	position: fixed;
	z-index: 100000;
	top:0px;
	left:0px;
	right:0px;
	left:0px;
	background: rgba(10,10,10,0.6);
	overflow: hidden;
	height: 100%;
	width: 100%;
}
.simple-loader .spinner div {
  width: 20px;
  height: 20px;
  position: absolute;
  left: -20px;
  top: 25%;
  background-color: #fff;
  border-radius: 50%;
  animation: move 4s infinite cubic-bezier(.2,.64,.81,.23);
}
.simple-loader .spinner div:nth-child(2) {
  animation-delay: 150ms;
}
.simple-loader .spinner div:nth-child(3) {
  animation-delay: 300ms;
}
.simple-loader .spinner div:nth-child(4) {
  animation-delay: 450ms;
}
@keyframes move {
  0% {left: 0%;}
  75% {left:100%;}
  100% {left:100%;}
}
.error_page{
	text-align: center;
}
.error_page .title {
  font-size: 72px;
  margin-bottom: 40px;
}

.error_page .title a{
  color:rgb(10,10,10);
  text-decoration:none;
  font-weight:200;
}

.error_page .title a span{
  font-weight:bold;
}

.error_page p{
  color:rgb(60,60,60);
  font-weight:300;
}

.error_page a{
  text-decoration: none;
  font-weight:bold;
  color:rgb(10,10,10);
}
/*comments*/
.comments{
	position: relative;
	img{
		z-index:10;
		position: absolute;
		height: 28px;
		width: 28px;
		top:0px;
	}
	.create{
		position: relative;
		padding-right: 110px;
		margin-bottom: 15px;
		textarea{
			padding: 10px 20px;
			position: relative;
			margin: 0px;
			font-size: 15px;
			margin-left: 35px;
			border-radius: 0 12px 12px 12px;
		}
		button{
			margin-left: 5px;
			position: absolute;
			top: 0;
			right: 0;
		}
	}
	.items{
		.item{
			margin-bottom: 10px;
			min-height: 50px;
			position: relative;
			.panel{
				 color:$gray-dark;
				 padding: 10px 20px;
				 position: relative;
				 margin: 0px;
				 font-size: 15px;
				 padding: 10px 20px;
				 margin-left: 35px;
				 border-radius: 0 12px 12px 12px;
			 }
			 .date{
				 position: absolute;
				 top:30px;
				 color:#8E8E94;
				 font-size: 10px;
			 }
			 .files a{
				color:rgba(200,200,200,1)!important;
				margin-right: 5px;
				padding-right: 5px;
				border-right: 1px solid rgba(200,200,200,1);
				&:hover{
					color:rgba(255,255,255,1)!important;
				}
				&:last-child{
					border-right:none;
				}
			}
		}
	}
}
/*messages*/
.del_file{
  color:#b22525;
  margin-left:8px;
  font-size: 17px;
  cursor: pointer;
}
#create .modal-body{
  padding-bottom: 50px;
}
.modal-body{
	position: static;
}
.modal-content{
	position: relative;
}
.messages #progress{
  height: 10px;
  position: absolute;
  width: 97%;
  bottom: 0px;
}
.messages #progress_fast{
  height: 5px;
  margin: 0px;
}
.content_messages{
  height: 362px;
  overflow: auto;
  position: relative;
	border: 2px solid $gray-base;
	background: $gray-base;
	border-radius: $border-radius-large $border-radius-large 0 0;
	padding: 31px;
	.item-message{
	  margin-bottom: 10px;
		min-height: 50px;
		img{
		  z-index:10;
		  position: absolute;
		  height: 28px;
		  width: 28px;
		  top:0px;
		}
		.panel{
			 color:$gray-light;
			 padding: 10px 20px;
			 position: relative;
			 margin: 0px;
			 font-size: 15px;
		 }
		 .date{
			 position: absolute;
			 top:30px;
			 color:#8E8E94;
			 font-size: 10px;
		 }
		 .files a{
	 	  color:rgba(200,200,200,1)!important;
	 	  margin-right: 5px;
	 	  padding-right: 5px;
	 	  border-right: 1px solid rgba(200,200,200,1);
			&:hover{
			  color:rgba(255,255,255,1)!important;
			}
			&:last-child{
			  border-right:none;
			}
	 	}
	}
	.load_old{
	  text-align: center;
	  margin-bottom: 15px;
	  color:rgba(150,150,150,0.6);
	  cursor: pointer;
	}
	.item-message.left{
		img{
		  left:0px
		}
		.panel{
		  margin-left: 28px;
			background: $gray;
			border-radius: 0 12px 12px 12px;
		}
		.date{
			left:0px;
		}
	}
	.item-message.right{
		img{
		  right:0px;
		}
		.panel{
		  margin-right: 28px;
			background: $brand-info;
			border-radius: 16px 0 16px 16px;
		}
		.date{
			right:0px;
			text-align: right;
		}
	}
}
.create_message_fast{
	position: relative;
	textarea{
		border:none;
		border-radius: 0 0 0 $border-radius-large;
		color: #C7C7C7;
		font-size: 15px;
		padding: 14px;
		height: 48px;
		overflow: hidden;
		margin-top: 5px;
		resize: none;
		padding-right: 60px;
		&:hover,&focus{
			-webkit-box-shadow: none;
			-moz-box-shadow: none;
			box-shadow: none;
		}
	}
	.progress{
		height: 5px;
		position: absolute;
		width: 100%;
		bottom: 28px;
	}
	#files_upload_fast{
		position: absolute;
	 	bottom: 53px;
		 padding-left: 5px;
	}
	#files_upload_fast>span{
	  padding-right: 5px;
	  margin-right: 5px;
	  border-right: 1px solid rgba(200,200,200,1);
	}
	#files_upload_fast>span:last-child{
	  border: none;
	}
	.fileinput-button{
		border: none;
    color: $gray;
    position: absolute;
    right: 60px;
    bottom: 0;
    padding: 0 14px;
    height: 48px;
    font-size: 18px;
    line-height: 48px;
	}
	button{
		border: none;
		color: $gray;
    font-size: 15px;
    position: absolute;
    right: 0px;
    bottom: 0;
    padding: 14px;
    height: 48px;
	}
}
.item-list-messages a:focus h4{
	color:#fff!important;
}
.content_messages::-webkit-scrollbar-track, .list_messages::-webkit-scrollbar-track
{
  border-radius: 0px;
  background-color: #F5F5F5;
}

.content_messages::-webkit-scrollbar, .list_messages::-webkit-scrollbar
{
  width: 7px;
  background-color:rgba(255,255,255,0.8);
}

.content_messages::-webkit-scrollbar-thumb, .list_messages::-webkit-scrollbar-thumb
{
  border-radius: 0px;
  background-color:rgb(27,28,28);
}
.messages .margin-top{
  margin-top: 15px;
}
.list_messages{
  height: 415px;
	border: 2px solid $gray-base;
	background: $gray-base;
	border-radius: $border-radius-large;
	padding: 0;
	.create{
		background: $gray-light;
		line-height:  48px;
		text-align: center;
		padding: 0;
		margin: 0;
		border: none;
		border-radius: 0;
		width: 100%;
		color:$gray;
		&:hover, &:focus{
			color:$brand-info;
		}
	}
	a{
		height: 64px;
		padding: 16px;
		border: none;
		margin-top: 3px;
		img{
			height: 32px;
			width: 32px;
			margin-right: 16px;
			padding: 0;
			display: inline-block;
		}
		.title{
			display: inline-block;
			margin: 0;
			color:$gray-dark;
			font-size: 15px;
			line-height: 32px;
		}
		.date{
			float: right;
			text-align: right;
			font-size: 11px;
			font-weight: 300;
			color:$gray-lighter-second;
			line-height: 15px;
		}
		&:hover, &.active, &:focus, &:active, &.active:hover{
			color:$gray-dark!important;
			background: #EFEFEF;
		}
	}
}


.system_logo a{
  color:rgb(10,10,10);
  text-decoration:none;
  font-weight:300;
}

.system_logo a span{
  font-weight:600;
}
.nav-pills{
	background: $gray-base;
	border-radius: $border-radius-large;
	margin-top: 10px;
	font-size: 0;
	overflow: hidden;
	&>li{
		margin: 0!important;
	}
	&>li>a{
		color: $gray;
		padding: 14px 32px;
		font-size: 15px;
		&:hover{
			background: $brand-active;
			color:$gray-light;
		}
	}
	&.small{
		background: none;
		margin: 0;
		li{
			margin-right: 8px!important;
		}
		li>a{
			border-radius: $border-radius-large;
			font-size: 12px;
			padding:5px 10px;
			line-height: 1.5;
			background: $gray-light;
			border: 1px solid $gray;
			&:hover,&.active{
				background: $brand-info;
				border-color: $brand-info;
			}
		}
		li.active>a{
			background: $brand-info;
			border-color: $brand-info;
		}
	}
}
.homepage{
		::-webkit-scrollbar-track
		{
			border-radius: 0px;
			background-color: rgb(27,28,28);
		}

	  ::-webkit-scrollbar
		{
			width: 7px;
			height: 7px;
			background-color: #F5F5F5;
		}

		::-webkit-scrollbar-thumb
		{
			border-radius: 0px;
			background-color: rgba(255,255,255,0.8);
		}
	.events_cards{
		height: 380px;
		h2{
			padding-bottom: 5px;
		}
		.events{
			background: $gray;
			position: absolute;
			width: 100%;
			height: 324px;
			overflow-y: auto;
			left: 0;
			padding: 0px 40px;
			.empty{
				color:$gray-light;
				font-size: 30px;
				line-height: 324px;
				position: absolute;
				top:0;
				left:100px;
			}
			.all{
				height: 317px;
				overflow: hidden;
				.today{
						transform:  translateX(-50%) translateY(-50%) rotate(-90deg);
						background: $brand-info;
						text-transform: uppercase;
						width: 317px;
						color:$gray-light;
						text-align: center;
						height: 60px;
						position: absolute;
						line-height: 60px;
						font-size:22px;
						font-weight: bold;
						top:158.5px;
				}
				.card{
					display: inline-block;
					overflow: hidden;
					width: 232px;
					height: 285px;
					background: $gray-light;
					border: 2px solid $gray-light;
					margin: 16px 8px;
					border-radius: $border-radius-large;
					padding: 16px;
					position: relative;
					&:hover{
						text-decoration: none;
					}
					&.active{
						border-color: $brand-info;
						.days, .month{
							color: $brand-info;
						}
					}
					&.first{
						margin-left: 50px;
					}
					.days{
						color:$gray;
						font-weight: 800;
						font-size: 32px;
						line-height: 24px;
					}
					.month{
						color:$gray;
						text-transform: uppercase;
						font-size: 12px;
						font-weight: 900;
					}
					.name{
						font-size: 22px;
						font-weight: 800;
						margin-top: 16px;
						line-height: 28px;
					}
					.bottom-section{
						position: absolute;
						bottom:0px;
						border-top: 1px solid $gray-lighter-second;
						width: 95%;
						right:-2px;
						padding-right: 10px;
						padding-top: 7px;
						padding-bottom: 7px;
						span{
							text-transform: lowercase;
							color:$gray-dark;
							font-size: 15px;
							line-height: 16px;
						}
					}
					.users{
						height: 55px;
						span{
							&:first-child{
								font-size: 11px;
								display: block;
							}
						}
						.photos{
							width: 100px;
							text-align: right;
							padding-right: 13px;
							top: 0px;
							position: absolute;
							right: 8px;
							top: 10px;
							img{
								height: 33px;
								display: inline-block;
								margin-right: -15px;
							}
							.more{
								height: 31px;
						    display: inline-block;
						    margin-right: -15px;
						    width: 31px;
						    background: $gray-light;
						    border-radius: 100%;
						    line-height: 31px;
						    text-align: center;
						    border: 1px $gray-lighter-second solid;
							}
						}
					}
					.localization{
						bottom:60px;
						color:$gray-lighter-second;
						text-align: right;
						span{
							color:$gray-dark;
							float: left;
						}
					}
					.type{
						border: none;
						bottom:90px;
						color:$gray-lighter-second;
						text-align: right;
					}
					.count_equipment{
						border: 2px solid $brand-info;
						position: absolute;
						right: 16px;
						top:16px;
						border-radius: 100%;
						width: 40px;
						height: 40px;
						text-align: center;
						line-height: 40px;
						color:$brand-info;
					}
				}
			}
		}
	}
	.news_list{
		h2{
			padding-bottom: 5px;
			margin-top: 20px;
		}
		.panel{
			padding-bottom: 40px;
			.news{
				line-height: 20px;
				font-size:15px;
				padding: 16px;
				cursor: pointer;
				border: none;
				margin: 2px 1px;
				span{
					color:$gray;
					font-size: 11px;
				}
			}
		}
	}
	.events_list{
		h2{
			padding-bottom: 5px;
			margin-top: 20px;
		}
		.panel{
			padding-bottom: 40px;
			.event{
				line-height: 20px;
				font-size:15px;
				line-height: 20px;
				padding: 16px;
				border: none;
				margin: 2px 1px;
				a{
					display: block;
				}
				span{
					color:$gray;
					font-size: 11px;
					display: block;
					line-height: 15px;
				}
				.confirm-btn-group{
					float: right;
					top:0px;
					.btn{
						padding: 6px;
						cursor: pointer;
						background: #efefef;
						border: none;
					}
					i{
						color:$gray-lighter-second;
						font-size: 20px;
					}
				}
			}
		}
	}
	.tasks_list{
		h2{
			padding-bottom: 5px;
			margin-top: 20px;
		}
		.panel{
			padding-bottom: 40px;
			.task{
				line-height: 20px;
				font-size:15px;
				line-height: 20px;
				padding: 16px;
				border: none;
				margin: 2px 1px;
				a{
					display: block;
				}
				span{
					color:$gray;
					font-size: 11px;
					display: block;
					line-height: 15px;
				}
				.confirm-btn-group{
					float: right;
					top:0px;
					.btn{
						padding: 6px;
						cursor: pointer;
						background: #efefef;
						border: none;
					}
					i{
						color:$gray-lighter-second;
						font-size: 20px;
					}
				}
			}
		}
	}
	.load_more{
		text-align: center;
		color:$gray;
	  display: block;
	  margin-top: 40px;
		text-decoration: none;
    cursor: pointer;
	  font-size: 16px;
	}
}
.sidebar{
	position: fixed;
	top:70px;
	bottom: 0px;
	width: 208px;
	background: $gray-base;
	margin-left: -218px;
	transition: linear 0.4s linear;
	overflow: hidden;
	z-index: 400;
	cursor: pointer;
	&.active{
		margin-left: -208px;
		width: 208px;
		overflow: auto;
		.button{
			color:$brand-info;
		}
		ul{
			opacity: 1;
			visibility:visible;;
		}
	}
	@media (min-width: $screen-xlg) {
		margin-left: -208px;
		width: 208px;
		overflow: auto;
	}
	.button{
		position: absolute;
		right: 12px;
		font-size: 18px;
		top:30px;
		@media (min-width: $screen-xlg) {
			color:$brand-info;
		}
	}
	ul{
		opacity: 0;
		transition: linear 0.4s linear;
		width: 100%;
		list-style: none;
		padding: 0;
		margin-top: 60px;
		margin-bottom: 0px;
		visibility: hidden;
		@media (min-width: $screen-xlg) {
			opacity: 1;
			visibility:visible;
		}
		li{
			a{
				width: 100%;
				color:$gray;
				padding: 14px;
				line-height: 20px;
				text-align: center;
				display: block;
				text-decoration: none;
				font-size: 15px;
				&:hover,&.active{
					background: $brand-info;
					color:$gray-light;
				}
			}
		}
	}
	@media (min-width: $screen-xlg) {
		margin-left:0!important;
	}
	&::-webkit-scrollbar-track
	{
		border-radius: 0px;
		background-color: rgb(27,28,28);
	}

	&::-webkit-scrollbar
	{
		width: 7px;
		background-color: #F5F5F5;
	}

	&::-webkit-scrollbar-thumb
	{
		border-radius: 0px;
		background-color: rgba(255,255,255,0.8);
	}
}
.paginate{
	.pagination li{
		&:first-child() a{
			border-radius:  $border-radius-large  0 0  $border-radius-large;
		}
		&:last-child() a{
			border-radius:  0 $border-radius-large  $border-radius-large 0;
		}
	}
}
.login_page{
		text-align: center;
		color:#fff;
		font-weight: 300;
		z-index: 100;

		h2{
			font-weight: 300;
			font-size: 34px;
		 	letter-spacing: -1.1px;
			padding-bottom:38px;
		}
		input.form-control{
			border-radius: 4px!important;
			height: 36px!important;
		 	padding: 8px 16px;
		  letter-spacing: -0.5px;
			font-size: 15px;
		}
		.system_logo{
			text-align: center;
			margin-top: 64px;
			font-size: 25px;
			a{
				color:#fff;
			}

		}
		.send_btn{
			background: #fff;
			width: 224px;
			border-radius: 5px;
			border:none;
			padding: 16px;
		 	font-size: 15px;
			letter-spacing: -0.5px;
			font-weight: 300;
		}
		.form-control{
			background: rgba(100,100,100,0.1)!important;
			border: solid 0.4px #fff !important;
			color:#fff!important;
			padding: 10px;
		}
		label{
		 font-weight: 600;
		 margin-bottom: 8px;
		}
		.form-group{
			text-align: left;
		}
		.checkbox label{
			font-weight: normal;
		}
}

@import "node_modules/eonasdan-bootstrap-datetimepicker/src/sass/bootstrap-datetimepicker-build.scss";
